<template>
  <div>
    <b-card no-body class="p-1">
      <validation-observer ref="reportRules">
        <b-row class="report-form">
          <b-col cols="6" md="3">
            <label for="from-date">{{ $t("From") }}</label>
            <flat-pickr
              id="from-date"
              v-model="start"
              class="form-control"
              placeholder="Select date"
              :config="{
                locale: $i18n.locale,
                allowInput: true,
              }"
              @input="rowData = []"
            />
          </b-col>
          <b-col cols="6" md="3">
            <label for="to-date">{{ $t("To") }}</label>
            <flat-pickr
              id="to-date"
              v-model="end"
              class="form-control"
              placeholder="Select date"
              :config="{
                locale: $i18n.locale,
                allowInput: true,
              }"
              @input="rowData = []"
            />
          </b-col>
          <b-col cols="12" md="3">
            <b-button
              variant="primary"
              class="my-2 w-100"
              @click="getReport"
              :disabled="isLoading"
            >
              {{ $t("Submit") }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-row
      class="print-section"
      v-if="!isLoading && Object.keys(rowData).length != 0"
    >
      <b-col cols="12" class="report-actions">
        <report-options
          path="/crm/client/account-statement"
          :serverParams="serverParams"
        ></report-options>
      </b-col>
      <b-col cols="12">
        <detailed-statement
          :rowData="rowData"
          :reportHeader="reportHeader()"
          :reportTitle="reportTitle"
        ></detailed-statement>
      </b-col>
    </b-row>
    <b-row v-else-if="isLoading" class="my-5 py-5">
      <b-col cols="12" class="text-center">
        <b-spinner
          v-for="variant in ['primary', 'success', 'info']"
          :key="variant"
          :variant="variant"
          class="mr-1"
          type="grow"
        />
      </b-col>
    </b-row>
    <b-row class="m-1 p-1">
      <b-col cols="12" class="text-center">
        <empty-row-data v-if="EmptyData" @falseEmptyData="EmptyData = false" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ReportOptions from "../components/reports/ReportOptions.vue";
import EmptyRowData from "../components/reports/EmptyRowData.vue";
import DetailedStatement from "./components/Statements/DetailedStatement.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    vSelect,
    ReportOptions,
    flatPickr,
    DetailedStatement,
    EmptyRowData,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },

  data() {
    return {
      openingBalance: 0,
      statementType: "Detailed",
      rowData: [],
      reportTitle: this.$t("Account Statement"),
      start: `${new Date().getFullYear()}-1-1`,
      end: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,

      isLoading: false,
      EmptyData: false,
    };
  },
  computed: {
    serverParams() {
      return {
        start: this.start,
        end: this.end,
        reportTitle: this.reportTitle,
        branch_name: this.$t("All Branches"),
      };
    },
  },

  created() {
    this.$store.commit("START_LOADING");

    this.$store.commit("STOP_LOADING");
  },

  methods: {
    getReport() {
      this.$refs.reportRules.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.EmptyData = false;
          this.rowData = [];

          this.$http
            .get(`/crm/client/account-statement`, {
              params: this.serverParams,
            })
            .then((response) => {
              this.rowData = response.data.statement;
              if (this.rowData.length == 0) {
                this.EmptyData = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },

    reportHeader(account_name = this.$t("Total")) {
      return {
        Branch: this.$t("All Branches"),
        From: this.start,
        To: this.end,
      };
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
