<template>
  <b-row>
    <b-col cols="4" class="px-2">
      <h1 class="text-primary">
        {{ reportHeader.Company }}
      </h1>
      {{ reportHeader.Branch }} <br />
      {{ currentDate() }}
    </b-col>
    <b-col cols="8">
      <b-row>
        <b-col v-for="(value, key) in reportHeader" :key="key" cols="6">
          <p v-if="key != '' && key != 'Company' && key != 'Branch'">
            <span class="font-weight-bolder"> {{ $t(key) }} </span> :
            {{ value }}
          </p>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="text-center mb-75">
      <hr />

      <h3 class="p-75 bg-secondary text-white w-50 m-auto rounded">
        {{ reportTitle }}
      </h3>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    reportHeader: Object,
    reportTitle: String,
  },
  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
      return date;
    },
  },
};
</script>

<style></style>
