<template>
  <div>
    <b-card
      no-body
      class="p-1 mb-1"
      :id="statement.code + '-' + statement.name"
    >
      <report-header
        :reportHeader="reportHeader"
        :reportTitle="reportTitle"
      ></report-header>
      <div class="table-responsive">
        <table class="table table-bordered small">
          <thead>
            <tr>
              <th>{{ $t("Date") }}</th>

              <th>{{ $t("Statement") }}</th>
              <th>{{ $t("Type") }}</th>
              <th>{{ $t("Serial") }}</th>
              <th>{{ $t("Debit") }}</th>
              <th>{{ $t("Credit") }}</th>
              <th>{{ $t("Balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="font-weight-bolder">
              <td colspan="6">{{ $t("Opening Balance") }}</td>

              <td>
                {{ statement.openingBalance | globalNumberDisplay }}
              </td>
            </tr>
            <tr v-for="(element, i) in statement.rows" :key="i">
              <td>{{ element.actual_date | formatDate }}</td>
              <td>{{ element.notes }}</td>
              <td>{{ $t(element.source) }}</td>
              <td>
                {{ element.source_serial }}
              </td>
              <td>{{ element.total_debit | globalNumberDisplay }}</td>
              <td>{{ element.total_credit | globalNumberDisplay }}</td>
              <td>
                <span class="d-block">{{
                  element.row_balance | globalNumberDisplay
                }}</span>
              </td>
            </tr>

            <tr class="font-weight-bolder" style="border: 2px solid #6f64e9">
              <td colspan="4" class="text-center">
                {{ $t("Total Transactions") }}
              </td>
              <td>
                {{ total_move_debit | globalNumberDisplay }}
              </td>
              <td>
                {{ total_move_credit | globalNumberDisplay }}
              </td>
              <td></td>
            </tr>

            <tr class="font-weight-bolder" style="border: 2px solid #6f64e9">
              <td colspan="4" class="text-center">
                {{ $t("Total") }}
              </td>
              <td>
                {{ totalDebit | globalNumberDisplay }}
              </td>
              <td>
                {{ totalCredit | globalNumberDisplay }}
              </td>
              <td></td>
            </tr>
            <tr class="font-weight-bolder" style="border: 2px solid #6f64e9">
              <td colspan="4" class="text-center">
                {{ $t("Balance") }}
              </td>
              <td>
                <span v-if="statement.account_nature == 'debit'">
                  {{ totalBalance | globalNumberDisplay }}
                </span>
              </td>
              <td>
                <span v-if="statement.account_nature == 'credit'">
                  {{ totalBalance | globalNumberDisplay }}
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BLink } from "bootstrap-vue";

import ReportHeader from "../../../components/reports/ReportHeader.vue";

export default {
  components: {
    BCard,
    BLink,
    ReportHeader,
  },
  props: {
    statement: Object,
    reportHeader: Object,
    reportTitle: String,
  },
  data() {
    return {
      totalBalance: 0,
      openingBalance: 0,
      total_move_debit: 0,
      total_move_credit: 0,
      add_branch: false,
    };
  },
  computed: {
    totalDebit() {
      if (this.statement.account_nature == "debit") {
        return this.total_move_debit + this.statement.openingBalance;
      } else {
        return this.total_move_debit;
      }
    },
    totalCredit() {
      if (this.statement.account_nature == "credit") {
        return this.total_move_credit + this.statement.openingBalance;
      } else {
        return this.total_move_credit;
      }
    },
  },
  created() {
    let startBalance = this.statement.openingBalance;

    let total_move_debit = 0;
    let total_move_credit = 0;

    const nature = this.statement.account_nature;

    this.statement.rows = this.statement.transactions.map((row) => {
      total_move_debit += row.total_debit;
      total_move_credit += row.total_credit;
      if (nature == "debit") {
        startBalance += row.total_debit - row.total_credit;
      } else {
        startBalance += row.total_credit - row.total_debit;
      }

      var b = row.branch_name || "";

      if (b.length > 0) {
        this.add_branch = true;
      } else {
        row.branch_name = "";
      }

      return {
        actual_date: row.actual_date,
        notes: row.notes,
        source: row.source,
        source_id: row.source_id,
        source_serial: row.source_serial,
        source_route: row.source_route,
        total_debit: row.total_debit.toFixed(2),
        total_credit: row.total_credit.toFixed(2),
        row_balance: startBalance.toFixed(2),
        branch_name: row.branch_name,
      };
    });
    this.totalBalance = startBalance.toFixed(2);

    this.total_move_debit += total_move_debit;
    this.total_move_credit += total_move_credit;
  },
};
</script>
