<template>
  <div>
    <sub-detailed
      :reportHeader="rHeader(rowData.name)"
      :reportTitle="reportTitle"
      :statement="rowData"
    />
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";

import SubDetailed from "./SubDetailed.vue";
export default {
  props: {
    rowData: Object,
    reportHeader: Object,
    reportTitle: String,
  },
  data() {
    return {
      totalBalance: 0,
      data: [],
    };
  },
  created() {},
  components: {
    BCard,

    SubDetailed,
  },

  methods: {
    rHeader(account_name) {
      return {
        ...this.reportHeader,
        Account: account_name,
      };
    },
  },
};
</script>
