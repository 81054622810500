<template>
  <div>
    <b-alert
      v-height-fade.appear
      :show="dismissCountDown"
      dismissible
      class="mb-0 p-5"
      variant="danger"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span>{{ $t("No Report Data Found") }}</span>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
export default {
  components: {
    BAlert,
  },
  data() {
    return {
      dismissSecs: 6,
      dismissCountDown: 0,
    };
  },
  directives: {
    "height-fade": heightFade,
  },
  created() {
    this.showAlert();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;

      if (dismissCountDown == 0) {
        this.$emit("falseEmptyData");
      }
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style></style>
