<template>
  <b-card no-body>
    <b-card-body
      class="pt-75 pb-0"
      style="border: 1px solid #7367f0; border-radius: 5px"
    >
      <div class="text-right col-6">
        <!-- Button: Print -->
        <b-button
          variant="outline-info"
          :disabled="Loading"
          @click="printReport"
          class="mx-2"
        >
          <feather-icon icon="PrinterIcon" />
          <span>{{ $t("Print") }}</span>
        </b-button>
        <b-button variant="outline-danger" :disabled="Loading" @click="viewPdf">
          <feather-icon icon="EyeIcon" />
          <span>{{ $t("PDF Preview") }}</span>
        </b-button>
      </div>
      <div class="d-flex flex-wrap my-1" v-if="Loading">
        <b-spinner
          v-for="variant in ['primary', 'success', 'info']"
          :key="variant"
          :variant="variant"
          class="mr-1"
          type="grow"
        />
      </div>
      <iframe id="pdf-frame"></iframe>
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardBody, BButton, BSpinner } from "bootstrap-vue";

export default {
  props: {
    path: String,
    serverParams: Object,
  },
  components: {
    BCard,
    BCardBody,
    BButton,
    BSpinner,
  },
  data() {
    return {
      Loading: false,
    };
  },
  methods: {
    printReport() {
      this.Loading = true;
      this.$http
        .get(`${this.path}/export/print`, {
          responseType: "blob",
          params: this.serverParams,
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          var objectURL = URL.createObjectURL(file);
          // window.open(objectURL);

          document.querySelector("#pdf-frame").src = "";
          document.querySelector("#pdf-frame").src = objectURL;
          // objectURL = URL.revokeObjectURL();
          setTimeout(function () {
            URL.revokeObjectURL(file);
          }, 3000);
        })
        .then(function () {
          window.setTimeout(function () {
            document.querySelector("#pdf-frame").contentWindow.print();
          }, 1000);
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    viewPdf() {
      this.Loading = true;
      this.$http
        .get(`${this.path}/export/print`, {
          responseType: "blob",
          params: this.serverParams,
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          var objectURL = URL.createObjectURL(file);
          window.open(objectURL);
          setTimeout(function () {
            URL.revokeObjectURL(file);
          }, 3000);
        })
        .finally(() => {
          this.Loading = false;
        });
    },
  },
};
</script>

<style scoped>
::v-deep #pdf-frame {
  visibility: hidden;
  max-width: 20px;
  height: 0;
}
</style>
